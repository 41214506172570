@import "./src/stylesheets/theme-vars-override.scss";
.meeple {
  position: absolute;
  width: 41px;
  height: 50px;
  transition: 0.5s ease-out;

  .bg {
    position: absolute;
    background-size: 100% 100%;
    width: 41px;
    height: 50px;
  }

  .tf {
    position: absolute;
    margin: 0px;
    bottom: -6px;
    right: -4px;
    padding: 0 2px;
    font-size: 28px;
    user-select: none;
    font-weight: 700;
    cursor: default;
  }
}

.giant {
  position: absolute;
  width: 48px;
  height: 62px;
  transition: 0.5s ease-out;

  .bg {
    position: absolute;
    background-size: 100% 100%;
    width: 48px;
    height: 62px;
  }

  .tf {
    position: absolute;
    bottom: -6px;
    right: -4px;
    padding: 0 2px;
    font-size: 28px;
    user-select: none;
    font-weight: 700;
    cursor: default;
  }
}

.builder {
  position: absolute;
  width: 48px;
  height: 62px;
  transition: 0.5s ease-out;

  .bg {
    position: absolute;
    background-size: 100% 100%;
    width: 48px;
    height: 62px;
  }

  .tf {
    position: absolute;
    bottom: -6px;
    right: -4px;
    padding: 0 2px;
    font-size: 28px;
    user-select: none;
    font-weight: 700;
    cursor: default;
  }
}

.pig {
  position: absolute;
  width: 62px;
  height: 33px;
  transition: 0.5s ease-out;

  .bg {
    position: absolute;
    background-size: 100% 100%;
    width: 62px;
    height: 33px;
  }

  .tf {
    position: absolute;
    bottom: -6px;
    right: -4px;
    padding: 0 2px;
    font-size: 28px;
    user-select: none;
    font-weight: 700;
    cursor: default;
  }
}

.ship {
  position: absolute;
  width: 62px;
  height: 48px;
  transition: 0.5s ease-out;

  .bg {
    position: absolute;
    left: -7px;
    background-size: 100% 100%;
    width: 62px;
    height: 48px;
  }

  .tf {
    position: absolute;
    bottom: -6px;
    right: 4px;
    padding: 0 2px;
    font-size: 28px;
    user-select: none;
    font-weight: 700;
    cursor: default;
  }
}

.meeple_grey {
  background-image: url('../../assets/meeple100_grey.png');
}
.meeple_orange {
  background-image: url('../../assets/meeple100_orange.png');
}
.meeple_red {
  background-image: url('../../assets/meeple100_red.png');
}
.meeple_blue {
  background-image: url('../../assets/meeple100_blue.png');
}
.meeple_yellow {
  background-image: url('../../assets/meeple100_yellow.png');
}

.giant_grey {
  background-image: url('../../assets/giant100_grey.png');
}
.giant_orange {
  background-image: url('../../assets/giant100_orange.png');
}
.giant_red {
  background-image: url('../../assets/giant100_red.png');
}
.giant_blue {
  background-image: url('../../assets/giant100_blue.png');
}
.giant_yellow {
  background-image: url('../../assets/giant100_yellow.png');
}

.builder_grey {
  background-image: url('../../assets/builder100_grey.png');
}
.builder_orange {
  background-image: url('../../assets/builder100_orange.png');
}
.builder_red {
  background-image: url('../../assets/builder100_red.png');
}
.builder_blue {
  background-image: url('../../assets/builder100_blue.png');
}
.builder_yellow {
  background-image: url('../../assets/builder100_yellow.png');
}

.pig_grey {
  background-image: url('../../assets/pig100_grey.png');
}
.pig_orange {
  background-image: url('../../assets/pig100_orange.png');
}
.pig_red {
  background-image: url('../../assets/pig100_red.png');
}
.pig_blue {
  background-image: url('../../assets/pig100_blue.png');
}
.pig_yellow {
  background-image: url('../../assets/pig100_yellow.png');
}

.ship_grey {
  background-image: url('../../assets/ship100_grey.png');
}
.ship_orange {
  background-image: url('../../assets/ship100_orange.png');
}
.ship_red {
  background-image: url('../../assets/ship100_red.png');
}
.ship_blue {
  background-image: url('../../assets/ship100_blue.png');
}
.ship_yellow {
  background-image: url('../../assets/ship100_yellow.png');
}

// different positioning/ sizing in meepleCont
.meepleInCont {
  display: inline-block;
  line-height: 35px;
  padding-left: 2px;
  padding-right: 2px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.meepleBtn {
  .bg {
    display: block;
    background-size: 100% 100%;
    width: 20px;
    height: 24px;
  }
}

.giantBtn {
  .bg {
    display: block;
    background-size: 100% 100%;
    width: 20px;
    height: 28px;
  }
}

.builderBtn {
  .bg {
    display: block;
    background-size: 100% 100%;
    width: 20px;
    height: 28px;
  }
}

.pigBtn {
  .bg {
    display: block;
    background-size: 100% 100%;
    width: 28px;
    height: 20px;
  }
}

.shipBtn {
  .bg {
    display: block;
    background-size: 100% 100%;
    width: 28px;
    height: 20px;
  }
}
