@import "./src/stylesheets/theme-vars-override.scss";
@import '../../stylesheets/sevencastles.theme.scss';

.meepleCont {
  position: absolute;
  z-index: 11;
  top: 44px;
  right: 4px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .count {
    font-size: 18px;
    //line-height: 35px;
    vertical-align: middle;
    padding-left: 2px;
    //padding-right: 2px;
  }
}
