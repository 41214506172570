@import "./src/stylesheets/theme-vars-override.scss";
@import '../stylesheets/core.theme.scss';

.mainCont {
  position: absolute;
  width: $column-size;
  left: $column-size;
  top: $header-size;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $background-color;
}

.header {
  @include panel-header;
}

.btnBar {
  gap: 4px;
  display: none;
}

@media (max-width: $mobile-width) {
  .mainCont {
    height: calc((100% / 3));
    width: 100%;
    left: 0px;
    top: calc((100% / 3));
  }

  .btnBar {
    display: flex;
  }
}

.title {
  @include title;
}

.body {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.list {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  padding: 4px;
  margin: 0px;
  box-sizing: border-box;
}

.tab {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.listItem {
  list-style: none;
}

.itemCont {
  display: flex;
  height: 32px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: $highlight-color;
}

.roomName {
  display: inline-block;
  margin-right: 4px;
  padding-left: 4px;
  width: 100px;
}

.tilesLeft {
  flex: 1;
  text-align: center;
}

.joinButton {
  cursor: pointer;
  font-size: 14px;
}

.users {
  display: flex;
  height: 32px;
  align-items: center;
  padding-left: 8px;
  font-size: 14px;
}
