@import "./src/stylesheets/theme-vars-override.scss";
@import '../stylesheets/sevencastles.theme.scss';

.mainCont {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.header {
  @include panel-header;
}

.title {
  @include title;
}

.body {
  padding: 8px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

}

.highlight {
  color: $highlight-color;
}

a:link, a:visited {
  color: $highlight-color;
}