@import "./src/stylesheets/theme-vars-override.scss";
@import '../stylesheets/core.theme.scss';

.mainCont {
  position: absolute;
  height: 100%;
  width: 100vw;
  z-index: 2001;
}

.bg {
  background-color: black;
  opacity: 0.3;  
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.panel{
  @include panel;
  min-width: 300px;
  max-width: 500px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}

.title {
  @include title;
  margin-left: 4px;
}

.header {
  @include panel-header;
}

.body {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  padding: 12px;
  box-sizing: border-box;
}

.btnBar {
  padding: 10px;
  display: flex;
  justify-content: space-around;
}
