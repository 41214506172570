@import "./src/stylesheets/theme-vars-override.scss";
@import '../stylesheets/sevencastles.theme.scss';

.mainCont {
  position: absolute;
  height: 100%;
  width: 100vw;
  z-index: 999;
}

.bg {
  background-color: black;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.3;
  top: 0px;
  left: 0px;
}

.panel {
  @include panel;
  width: 500px;
  max-width: 100vw;
  max-height: 100dvh;
  position: absolute;
  overflow: auto;
}

@media (max-width: $mobile-width) {
  .panel {
    width: 100vw;
    height: 100dvh;
  }
}

.header {
  @include panel-header;
}

.title {
  @include title;
  margin-left: 4px;
  display: flex;
  align-items: center;
}

.btnBar {
  padding: 10px;
  display: flex;
  justify-content: space-around;
}

.body {
  box-sizing: border-box;
  margin: 4px 4px 4px 8px;
  flex: 1;
  overflow: auto;
}

.subtitle {
  @include subtitle;
  margin-top: 6px;
  margin-bottom: 6px;
}

.gameList {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(94px, 1fr));
  gap: 4px;
  margin-bottom: 12px;
}

.gameType {
  padding: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.selected {
  background-color: $btn-color;
  border-radius: 4px;
}

.gameOptions {
  display: flex;
}

.optionRow {
  display: flex;
  justify-items: center;
  line-height: 1em;
  padding: 6px;

  label {
    margin-right: 8px;
  }
}

.gameTitle {
  margin-bottom: 4px;
  white-space: nowrap;
}

.tileCont {
  position: relative;
  width: 75px;
  height: 75px;
  transform: scale(0.5);
  transform-origin: 0px 0px;
}

.credits {
  color: $highlight-color;
}

.detail {
  margin: 8px 4px 4px 4px;
  white-space: nowrap;
}
