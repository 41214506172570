@import "./src/stylesheets/theme-vars-override.scss";
@import '../stylesheets/core.theme.scss';

.mainCont {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include title;
  margin: 20px;
  font-size: $font-size-main-title + 4;
}

.btnBar {
  width: 200px;
  padding: 10px;
  display: flex;
  justify-content: space-around;
}