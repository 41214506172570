@import "./src/stylesheets/theme-vars-override.scss";


.body {

  p {
    text-align: center;
    line-height: 1.8em;
    margin: 0;
  }
}