@import "./src/stylesheets/theme-vars-override.scss";
@import '../../stylesheets/sevencastles.theme.scss';

.overlayCont {
  position: absolute;
  left: 100px;
  bottom: 120px;
  transform: scaleX(0.25) scaleY(0.25);
  display: flex;
}

.tileBg {
  width: 260px;
  height: 300px;
  position: absolute;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  background-color: rgba($color: #ddd, $alpha: 0.5);
}

.count {
  color: $highlight-color;
  position: absolute;
  font-size: 56px;
}
