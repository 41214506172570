@import "./src/stylesheets/theme-vars-override.scss";
@import '../stylesheets/core.theme.scss';

.gameCont {
  width: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: $column-size;
}

@media (max-width: $mobile-width) {
  .gameCont {
    top: 33.33%;
    left: 0px;
  }

  .gameCont.playing {
    top: 0;
  }
}

.gameCont.expanded {
  left: 0px;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: $header-size;
  background-color: $header-color;
  border-bottom: solid 1px $border-color;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  //
  position: absolute;
  z-index: 10;
}

.title {
  @include title;
}

.startBtnContBg {
  position: absolute;
  z-index: 20;
  height: 100%;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
}

.startBtnCont {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  text-align: center;
  gap: 6px;
  height: 74px;
  justify-items: stretch;

  div {
    width: 74px;
  }

  .or {
    display: flex;
    align-items: center;
    text-align: center;
    width: 20px;
    font-style: italic;
  }
}

.leaveBtnCont {
  position: absolute;
  z-index: 21;
  top: 4px;
  right: 2px;
}

.chatBtn {
  position: absolute;
  z-index: 11;
  bottom: 4px;
  left: 4px;
  box-sizing: border-box;
}

.chatCont {
  position: absolute;
  z-index: 11;
  bottom: 0px;
  left: 0px;
  height: 100%;
}
@media (max-width: $mobile-width) {
  .chatCont.show {
    left: 0;
    right: 0;
  }
}

.submitButton {
  cursor: pointer;
  font-size: 14px;
}

.rightBtnCont {
  position: absolute;
  z-index: 14;
  right: 4px;
  top: 50%;
  transform: translateY(-100%);
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 4px;
}

.leftBtnCont {
  position: absolute;
  z-index: 14;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 4px;
}
