@import "./src/stylesheets/theme-vars-override.scss";
@import '../../stylesheets/sevencastles.theme.scss';

.mainCont {
  display: grid;
  padding: 4px;
  width: auto;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  overflow: auto;
}

.familyItem {
  overflow: hidden;
}

.thumbnail {
  height: 200px;
  cursor: pointer;
}

.header {
  @include panel-header;
}

.title {
  font-size: $font-size-section-title;
  color: $highlight-color;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}