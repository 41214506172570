@import "./src/stylesheets/theme-vars-override.scss";
@import '../../stylesheets/sevencastles.theme.scss';

.mainCont {
  position: absolute;
  top: $header-size;
  left: calc(2 * #{$column-size});
  bottom: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: $background-color;
}

.closeBtn {
  display: none;
}

@media(max-width: $mobile-width){
  .mainCont {
    left: 0px;
    top: 0px;
    display: none;
  }
  .mainCont.show {
    display: flex;
  }

  .closeBtn {
    display: block;
  }

}

.header {
  @include panel-header;

}

.title {
  @include title;
}

.leftGroup {
  display: flex;
  align-items: center;
}

.body {
  padding: 4px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  span, h3 {
    color: $highlight-color;
  }
}


.tileList {
  display: flex;
  gap: 4px;
  width: 100%;
  flex-wrap: wrap;
}

.tileCont {
  position: relative;
  width: 75px;
  height: 75px;
  transform: scale(0.5);
  transform-origin: 0px 0px;

  .label {
    position: absolute;
    top: 4xp;
    left: 4px;
    color: $background-color;
    transform: scale(2);
    transform-origin: 0px 0px;
  }
}

.guideImg {
  margin-right: 10px;
}