$mobile-width: 600px;

$text-color: #fff !default;
$primary-color: #0fa9c2 !default;
$background-color-light: darken($primary-color, 17%) !default;
$background-color: darken($primary-color, 25%) !default;
$header-color: $background-color !default;

$highlight-color: lighten(saturate($primary-color, 10%), 20%) !default;


$btn-color: $primary-color !default;
$secondary-btn-color: $header-color !default;

$border-color: rgba($primary-color, 0.6) !default;

$font-size-normal: 16px;
$font-size-subtitle: 17px;
$font-size-section-title: 18px;
$font-size-main-title: 20px;

$header-size: 40px;
$column-size: 250px;

$yellow: #ffee00;

// fonts

@font-face {
  font-family: 'Fredericka';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Fredericka.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: 'RobotoBold';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/RobotoCondensed-Bold.ttf');
}

// html tags

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  font-family: 'RobotoBold', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-normal;
  background-color: $background-color;
  color: $text-color;
}

input {  
  font-family: 'RobotoBold', 'sans-serif';
  color: $text-color;
  font-size: $font-size-normal;
}

input:focus{
  outline: none;
}

//  create dialog fade in-out anim

.fade-enter {
  opacity: 0;
  pointer-events: none;
}

.fade-exit {
  opacity: 1;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 1000ms;
}
.fade-enter-done {
  opacity: 1;
}

.fade-exit-done {
  opacity: 0;
  pointer-events: none;
}


// meeple recover animation

.recover-appear {
  transform: scale(1,1);
}
.recover-appear-active {
  transform : scale(3, 3);
  transition: transform 0.3s;
  transition-timing-function: cubic-bezier(0.380, 1.460, 0.705, 1.650);
  transform-origin: 50% 50%;
}

// mixins
@mixin panel {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: $background-color-light;
  display: flex;
  flex-direction: column;

}

@mixin title {
  font-size: $font-size-section-title;
  color: $highlight-color;
}

@mixin subtitle {
  font-size: $font-size-subtitle;
  color: $highlight-color;
}

@mixin panel-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: $header-size;
  border-bottom: solid 1px $border-color;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
}