@import "./src/stylesheets/theme-vars-override.scss";
@import '../stylesheets/core.theme.scss';

.mainCont {
  position: absolute;
  width: $column-size;
  left: 0;
  top: $header-size;
  height: calc((100% - #{$header-size}) / 2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $background-color;
}

.header {
  @include panel-header;
}

@media(max-width: $mobile-width){
  .mainCont {
    height: calc((100% / 3 ) - #{$header-size});
    width: 100%;
  }

  .header {
    height: 0px;
    display: none;
  }
}

.title {
  font-size: $font-size-section-title;
  color: $highlight-color;
}
