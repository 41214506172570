@import "./src/stylesheets/theme-vars-override.scss";
@import '../stylesheets/core.theme.scss';

.mainCont {
  width: 100%;
  height: $header-size;
  flex-basis: $header-size;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $header-color;
  border-bottom: solid 1px $border-color;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.title {
  @include title;
}

@media (max-width: $mobile-width) {
  .disconnect {
    display: none;
  }
}
