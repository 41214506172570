@import "./src/stylesheets/theme-vars-override.scss";
.coverCont {
  margin: 20px;
  max-height: 420px;
  overflow: hidden;

  .pageCover {
  }

  @media (max-width: 600px) {
    width: 300px;

    .pageCover {
      transform: translateX(-44.4%);
    }
  }
}
