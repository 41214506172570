@import "./src/stylesheets/theme-vars-override.scss";
.node {
  position: absolute;
  width: 8px;
  height: 8px;
  border: solid 2px red;
  border-radius: 8px;
  background-color: white;
  margin-top: -4px;
  margin-left: -4px;
}

.tileItem.koenigsburg .node {
  transform: scaleX(2) scaleY(2);
}

.last {
  width: 142px;
  height: 142px;
  border: solid 4px orange;
  position: absolute;
}

.act {
  width: 142px;
  height: 142px;
  border: solid 4px red;
  position: absolute;
}
