@import "./src/stylesheets/theme-vars-override.scss";
@import '../stylesheets/core.theme.scss';

.mainCont {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
