@import "./src/stylesheets/theme-vars-override.scss";
@import '../stylesheets/core.theme.scss';

.mainCont {
  position: absolute;
  //width: $column-size;
  width: 0px;
  height: calc((100% - #{$header-size}) / 2);
  left: 0px;
  bottom: 0px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  background-color: $background-color;
}

.show {
  display: flex;
  width: $column-size;
}

.header {
  @include panel-header;
}

.guideBtn {
  display: none;
}


@media(max-width: $mobile-width){
  .mainCont {
    height: calc((100% / 3 ));
    display: none;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .show {
    display: flex;
    width: 100%;
  }
  
  .guideBtn {
    display: block;
  }

  .hideGuide {
    display: none;
  }
}

.closeBtn {
  display: none;
}

.showClose {
  display: block;
}

.title {
  @include title;
}


.entries {
  flex: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 4px;
}

.displayName {
  color: $highlight-color;
}

.devMessage {
  color: $yellow;
}

.inputCont {
  display: flex;
  padding-top: 4px;
  box-sizing: border-box;
  gap: 4px;
  justify-content: stretch;
  padding: 4px;
  width: 100%;

  input {
    flex: 1;
    border: solid 1px $border-color;
    background-color: $background-color;
    color: $text-color;
    padding-left: 4px;
  }
}