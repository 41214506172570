@import "./src/stylesheets/theme-vars-override.scss";
@import '../../stylesheets/sevencastles.theme.scss';

.tileMap {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  overflow: hidden;
}

.canvas {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-75px);

  .bg {
    background-color: $background-color;
    width: 5000vh;
    height: 5000vw;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
  }
}

.rightBtnCont {
  position: absolute;
  z-index: 14;
  right: 4px;
  top: 50%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.skipBtn {
  position: absolute;
  z-index: 11;
  bottom: 4px;
  right: 4px;
  box-sizing: border-box;
}

.submitBtn {
  position: absolute;
  z-index: 11;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
}
