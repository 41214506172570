@import "./src/stylesheets/theme-vars-override.scss";
.player {
  display: flex;
  flex-direction: row;
  max-width: calc(100vw - 100px);
}

.displayName {
  width: 120px;
}

.score {
  width: 80px;
}

.misses {
  width: 60px;
  color: orangered;
  padding-right: 6px;
}

.active {
  width: 40px;
}

.tradeIcon {
  width: 22px;
  height: 22px;
  margin-top: -4px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.tradeLabel {
  display: inline-block;
  padding-left: 6px;
  width: 30px;
}

.tradeGrain {
  background-image: url('../../assets/trade_grain.png');
}
.tradeWine {
  background-image: url('../../assets/trade_wine.png');
}
.tradeCloth {
  background-image: url('../../assets/trade_cloth.png');
}
