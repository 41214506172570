@import "./src/stylesheets/theme-vars-override.scss";
@import '../stylesheets/core.theme.scss';

.list {
  padding: 4px;
  margin: 0px;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.listItem {
  list-style: none;
  height: 32px;
  display: flex;
  align-items: center;
}

.listCont {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.gameCount {
  flex: 0 0 50px;
  color: $highlight-color;
}

.displayName {
  flex: 1 0 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
