@import "./src/stylesheets/theme-vars-override.scss";
.tileItem {
  position: absolute;
  width: 150px;
  height: 150px;
  transition: ease-out 0.2s;

  &.overlay {
    pointer-events: none;
  }
}

.active {
  position: absolute;
  width: 260px;
  height: 300px;
  background-color: rgba($color: #ddd, $alpha: 0.5);
  pointer-events: none;
}

.rotCont {
  position: absolute;

  .innerCont {
    pointer-events: none;
  }

  &.koenigsburg {
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    background-image: url('../../assets/tilemap_koenigsburg@2x.png');
    background-color: rgba($color: #ddd, $alpha: 0);
  }

  &.koenigsburg.rhombus {
    clip-path: polygon(50% 0, 50% 50%, 0 75%, 0 25%);
    background-image: url('../../assets/tilemap_rombus.png');
    background-color: rgba($color: #ddd, $alpha: 0);
  }
}

.last {
  border: solid 4px orange;
  position: absolute;
}

.act {
  border: solid 4px red;
  position: absolute;
}

.tileItem.koenigsburg {
  width: 260px;
  height: 300px;

  .last {
    width: 252px;
    height: 300px;
  }
  .act {
    width: 252px;
    height: 300px;
  }
}

.tileItem.koenigsburg.rhombus {
  width: 130px;
  height: 226px;

  .last {
    width: 122px;
    height: 226px;
  }
  .act {
    width: 122px;
    height: 226px;
  }
}
