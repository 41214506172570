@import "./src/stylesheets/theme-vars-override.scss";
.tileItem {
  position: absolute;
  width: 150px;
  height: 150px;
  transition: ease-out 0.2s;
}

.rotCont {
  position: absolute;

  .innerCont {
    pointer-events: none;
  }

  &.ccson {
    background-image: url('../../assets/tilemap_carcassonne.png');
  }

  &.sea {
    background-image: url('../../assets/tilemap_sea.png');
  }

  &.castle {
    background-image: url('../../assets/tilemap_castles.png');
  }

  &.koenigsburg {
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    background-image: url('../../assets/tilemap_koenigsburg@2x.png');
  }
}

.tileItem.koenigsburg {
  width: 260px;
  height: 300px;

  .last {
    width: 252px;
    height: 300px;
  }
  .act {
    width: 252px;
    height: 300px;
  }
}

.last {
  width: 142px;
  height: 142px;
  border: solid 4px orange;
  position: absolute;
}

.act {
  width: 142px;
  height: 142px;
  border: solid 4px red;
  position: absolute;
}
