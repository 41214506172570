@import "./src/stylesheets/theme-vars-override.scss";
@import '../../stylesheets/sevencastles.theme.scss';

.tilesLeft {
  position: absolute;
  top: 11px;
  left: 50%;
  z-index: 13;
  transform: translateX(-50%);
}
