@import "./src/stylesheets/theme-vars-override.scss";
@import '../stylesheets/core.theme.scss';

.bg {
  display: inline-block;
  padding: 6px 10px;
  color: white;
  background-color: $btn-color;
  border: solid 1px $btn-color;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    margin-right: 4px;
  }
}

.disabled {
  opacity: 0.5;
}

.primary {
  background-color: $secondary-btn-color;
  border-color: $secondary-btn-color;

  color: white;
}

.secondary {
  //background-color: $secondary-btn-color;
  background-color: rgba($secondary-btn-color, $alpha: .0);
  border-color: rgba($secondary-btn-color, $alpha: .0);

  color: $highlight-color;
  padding: 6px 6px;
}

.small {
  padding: 6px 4px;
}

.toggled {
  border: solid 1px $highlight-color;
}